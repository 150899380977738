export const voldet = [
  {
    "Name": "Kannaiahgari Sai Manish",
    "Mail": "kannaiahgarimanish@gmail.com",
    "Team": "Social media",
  },
  {
    "Name": "Cheeti Rishika",
    "Mail": "rishikacheeti14@gmail.com",
    "Team": "Social media",
  },
  {
    "Name": "Abhinav Sai Janipireddy",
    "Mail": "abhinavsai.janipireddy@gmail.com",
    "Team": "Social media",
  },
  {
    "Name": "Meghana Mekala",
    "Mail": "meghanaaravindmekala@gmail.com",
    "Team": "Social media",
  },
  {
    "Name": "Balmoori Shriya",
    "Mail": "shriyarao1512@gmail.com",
    "Team": "Social media",
  },
  {
    "Name": "Bakka Bhavani",
    "Mail": "bhavanibakka1903@gmail.com",
    "Team": "Social media",
  },
  {
    "Name": "Bhanu",
    "Mail": "saibhanupalla564@gmail.com",
    "Team": "Creative",
  },
  {
    "Name": "Akhil Aaditya Santosh Nalam",
    "Mail": "aaditya10012004@gmail.com",
    "Team": "Creative",
  },
  {
    "Name": "Akhil",
    "Mail": "akhil@gmail.com",
    "Team": "Creative",
  },
  {
    "Name": "Shashank Reddy",
    "Mail": "shashankreddy@gmail.com",
    "Team": "Creative",
  },
  {
    "Name": "Lakshita",
    "Mail": "laksita2004@gmail.com",
    "Team": "Technical",
  },
  {
    "Name": "Taraka Kiranmayi",
    "Mail": "tarakakiranmayi@gmail.com",
    "Team": "Technical",
  },
  {
    "Name": "Siddartha Samisetty",
    "Mail": "samisettysiddartha2004@gmail.com",
    "Team": "Technical",
  },
  {
    "Name": "K. Sathwik Reddy",
    "Mail": "sathwikreddy154@gmail.com",
    "Team": "Technical",
  },
  {
    "Name": "Ch. Anand",
    "Mail": "anandch119@gmail.com",
    "Team": "Technical",
  },
  {
    "Name": "Ronak Agrawal",
    "Mail": "reactoutron@gmail.com",
    "Team": "Technical",
  },
  {
    "Name": "Sirikonda Gangothri",
    "Mail": "sirikondagangothri186@gmail.com",
    "Team": "Technical",
  },
  {
    "Name": "Vibha Bhat",
    "Mail": "vibha.shrinath@gmail.com",
    "Team": "Events and PR",
  },
  {
    "Name": "Viramallu Sanjana",
    "Mail": "sanjanaviramallu@gmail.com",
    "Team": "Events and PR",
  },
  {
    "Name": "Rithika Rayapudi",
    "Mail": "rithikarayapudi@gmail.com",
    "Team": "Events and PR",
  },
  {
    "Name": "Abhilash Moturi",
    "Mail": "moturiabhilash@gmail.com",
    "Team": "Events and PR",
  },
  {
    "Name": "Korla Prathyusha",
    "Mail": "prathyushakorla2004@gmail.com",
    "Team": "Events and PR",
  },
  {
    "Name": "Poladi Renuka",
    "Mail": "renukapoladi5@gmail.com",
    "Team": "Events and PR",
  },
  {
    "Name": "Puli Anvitha",
    "Mail": "anvithapuli2005@gmail.com",
    "Team": "Events and PR",
  },
  {
    "Name": "Mandala Sree Harshini",
    "Mail": "sreeharshinimandala@gmail.com",
    "Team": "Design",
  },
  {
    "Name": "Panyala Shivalini Reddy",
    "Mail": "panyalashivalini14@gmail.com",
    "Team": "Design",
  },
  {
    "Name": "Nani Gunji",
    "Mail": "nanigunji44@gmail.com",
    "Team": "Design",
  },
  {
    "Name": "Kancherla Swathi",
    "Mail": "swatikancherla6@gmail.com",
    "Team": "Design",
  },
  {
    "Name": "Vaishnavi Bejgam",
    "Mail": "vaishnavibejgam55555@gmail.com",
    "Team": "Design",
  },
  {
    "Name": "K. Vaibhava Lakshmi",
    "Mail": "kuchurvaibhavalakshmi@gmail.com",
    "Team": "Design",
  },
  // New Members in Coding Team
  {
    "Name": "B. Tejaswini",
    "Mail": "tejuchowdary2004@gmail.com",
    "Team": "Coding",
  },
  {
    "Name": "K. Sai Harsha Vardhan",
    "Mail": "harshaspartax18@gmail.com",
    "Team": "Coding",
  },
  {
    "Name": "Kaviti Tanuja",
    "Mail": "tanukaviti2003@gmail.com",
    "Team": "Coding",
  },
  {
    "Name": "N. Sravya Reddy",
    "Mail": "sravyareddy.n06@gmail.com",
    "Team": "Coding",
  },
  {
    "Name": "N. Vamshi",
    "Mail": "nasanivamshivamshi@gmail.com",
    "Team": "Coding",
  },
  {
    "Name": "N. Varun Gopal",
    "Mail": "varungopalneela@gmail.com",
    "Team": "Coding",
  },
  {
    "Name": "A. Mahesh Reddy",
    "Mail": "akkulurimaheshreddy@gmail.com",
    "Team": "Coding",
  },
  {
    "Name": "Challa Vyshnavi Reddy",
    "Mail": "chvry03@gmail.com",
    "Team": "Coding",
  },
  {
    "Name": "Pranay Thangellapalli",
    "Mail": "pranaythangellapally1029@gmail.com",
    "Team": "Coding",
  }
];
