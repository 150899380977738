export const CoreTeam5=[
    {
        Position:'Chairperson',
        Name:'N Siddarth Reddy',
        SocialHandles:[' https://www.linkedin.com/in/siddharth-reddy-577ba026a/'],
        ImgPath:'sid2.jpeg'
    },
    {
        Position:'Vice Chairperson',
        Name:'Sravya Reddy',
        SocialHandles:['https://www.linkedin.com/in/sravyareddy16'],
        ImgPath:'SR.jpeg'
    },
    {
        Position:'Secretary',
        Name:'Neela Varun Gopal',
        SocialHandles:['https://www.linkedin.com/in/varun-gopal-87617b225/'],
        ImgPath:'V.jpeg'
    },
    {
        Position:'Treasurer',
        Name:'Krishna Sai B',
        SocialHandles:['https://www.linkedin.com/in/krishnasai-billakanti'],
        ImgPath:'Ks.jpeg'
    },
    {
        Position:'Membership Chair',
        Name:'Praneetha Madala',
        SocialHandles:['https://www.linkedin.com/in/praneetha-madala'],
        ImgPath:'Pm.jpeg'
    },
    {
        Position:'Webmaster',
        Name:'Chandrakethan S ',
        SocialHandles:['https://www.linkedin.com/in/chandrakethan-sivarathri/'],
        ImgPath:'csk2.jpeg'
    },
    {
        Position:'Activities Cordinator',
        Name:'Sai Harsha Vardhan',
        SocialHandles:['https://www.linkedin.com/in/kharsha/'],
        ImgPath:'harsh2.jpeg'
        
    }

]
export const TeamHeads5=[
    {
        Position:'Social Media Head',
        Name:'Srinibha Srikanth',
        SocialHandles:['https://www.linkedin.com/in/srinibha-srikanth-875a50255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'],
        ImgPath:'SS.jpeg'
    },
    {
        Position:'Events & PR Head',
        Name:'Aasritha Bibireddy',
        SocialHandles:['http://www.linkedin.com/in/aashritha-bibireddy-1a33a222b'],
        ImgPath:'Ab.jpeg'

    },{
        Position:'Technical Head',
        Name:'Sai Harsha Vardhan',
        SocialHandles:['https://www.linkedin.com/in/kharsha/'],
        ImgPath:'harsh2.jpeg'
    },
    {
        Position:'Design Team Head',
        Name:'M Lalitha Bhavana',
        SocialHandles:['www.linkedin.com/in/lalitha-bhavana-6b402425a'],
        ImgPath:'lali2.jpeg'
    },
    {
        Position:'Coding Team Head',
        Name:'Kaviti Tanuja',
        SocialHandles:['https://www.linkedin.com/in/kaviti-tanuja-326058249/'],
        ImgPath:'Kv.jpeg'
    }
]