export const events2020 = [
  {
    title: 'CODE IGNITE',
    date: '5 April 2021 - 7 April 2021',
    for: 'IT students',
    description: "A developer survey found that Python is the programming language that most people want to learn. Broadly, most emerging technologies use Python as it has a clean syntax, greatly refines the readability of code, and is suitably versatile. With an exponentially growing community around Data Science, Machine Learning, AI, Web Development, and more, Python is a language that opens programming access to the world. So, ACM VNRVJIET conducted a Python Programming Workshop - CODE IGNITE from 05-04-2021 to 07-04-2021. All participants who met the requirements were issued with an e-certificate upon successful completion.",
    imgPaths: ['code.jpeg']
  },

  {
    title: 'TECH SYMPOSIUM',
    date: '12 January 2021',
    for: '1st year IT students (class of 2020-2024)',
    description: "ACM VNRVJIET is all set to dive into its first event organized for 2020-2024 batch students on 12 January 2021. Do fill the form where you'll be asked a series of questions to know your area of interest in the field of technology, new terminology that you'd like to know about. In the session, we'll be expanding upon some of the most sought-after subjects, the career oppurtunities that these fields provide and their usefulness in today's world. This is an exclusive event for ACM members.",
    imgPaths: ['TechSym.jpeg']
  },
  {
    title: 'CONQUESTADOR',
    date: '25 January 2021',
    for: '1st year students',
    description: "There is no such things as a self-made man. You will reach your goals only with the help of others\". ACM has always working to develop the master mind's and to introduce how the real world of a Engineer works. On 25th Jan, ACM conducted an Interaction session with the help of alumni, final year students and the founder's of a Start-up. During the session, they shared the up's and down's of their careers and experiences with wonderful words of inspiration and fruitful advices. As most of the first year students were involved in the session they gave a proper guidance towards their goals and clarified their doubts.",
    imgPaths: ['conquestador.jpg']
  },
  {
    title: 'WEB DEVELOPMENT WORKSHOP FOR FIRST YEARS',
    date: '10 February 2020 - 11 February 2020',
    for: '1st year IT students',
    description: 'A two day web development workshop was conducted for first years. It was conducted on 10th and 11th of February of 2020. A total of 115 students attended the workshop, which focused on HTML and CSS. This workshop, which was conducted by the third years, enabled not only the first years, but also the second and third year students to learn new things. The participants were eager to learn about the fundamentals of web-based development tools and showed great enthusiasm. The workshop was hands-on, enabling the participants to apply their new-found skills in the lab session. It was also a great mode for the juniors to interact and learn new things from their seniors.',
    imgPaths: ['webdev.jpeg']
  },
  {
    title: 'PR FOR SECOND YEARS',
    date: '8 February 2020',
    for: '2nd year IT students',
    description: 'On 8th of February 2019, the third year students of team ACM conducted an interactive PR session to educate second years of IT department about how ACM would be helpful to upgrade their portfolios and their knowledge on upcoming technologies. The session was short and concise enough to give out the necessary information any tech geek would aspire for. They also talked about how they were planning to reach their goals and ideologies.',
    imgPaths: ['pr2nd.jpeg']
  },
  

]