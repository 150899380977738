export const events2018 = [

    {
      title: 'REPUBLIC DAY CELEBRATIONS',
      date: '26 January 2019',
      for: '',
      description: 'When the whole country was celebrating the republic day, here at VNR we were also celebrating the newly formed ACM. It was the first time our student chapter participated in the salute parade.In Salute parade every student chapter and all the clubs of VNR come up with a theme and parade for its cause. ACM has come up with the theme "History of technological advancements". We projected the topics like communication in army, army weapons advancements, railway development, irrigation projects and their usage, cyber security and many more.',
      imgPaths: ['rep19.jpeg']
    },
    {
      title: 'PR FOR SECOND YEARS',
      date: '20 January 2019',
      for: '2nd year IT students',
      description: 'On 20th Jan 2019, the third year students of team ACM have taken an interactive PR session to inform the second years of IT department about how ACM will be helpful to upgrade their portfolios and their knowledge on upcoming technologies. The session was short and crisp enough to give out the necessary information any coder would aspire for. Here at ACM we believe communication is the key, so the students have been assured the seniors support and shared experiences.',
      imgPaths: ['pr219.jpeg']
    },
    {
      title: 'ACM VNRVJIET INAUGURAL CEREMONY',
      date: '24 October 2018',
      for: '',
      description: 'VNR VJIET has been authorized to run an ACM student body. VNR VJIET is the 5th institution in Telangana state which has received this authorization. Student bodies provide unique opportunities for networking among students within the community and other local communities outside the institute.VNR VJIET organized an inaugural ceremony on campus at 10:30 a.m on 24th Oct 2018, to introduce ACM to the students. Dr. Ponnurangam Kumaraguru, an Associate Professor at IIIT Delhi was invited as the Chief Guest. He is an enthusiast of Cyber Security, computational social science and data science for social good. He is one of the eminent speakers of ACM India. He is also the Founding Head of Cyber Security Education and Research Centre (CERC) at IIIT Delhi and a visiting researcher at Data Security council of India.',
      imgPaths: ['inaug.jpeg']
    },
    {
      title: 'PROMOTION EVENT',
      date: '',
      for: '',
      description: 'Pre Event for promoting Salute.A pre-event for salute is organised by every club of the college a day or two before the Republic day,every year.The best way to know someone is to know their true, unbiased thoughts. Keeping true to this sprirt, as part of the Pre-Event for the Salute Parade, we asked three questions and collected students and faculty\'s responses,opinions and their varied version on those topics.Notes were handed out to enthusiatic participants, who would then write their opinion on the same and stick them on. Dr C.D Naidu garu, our Principal made our event prominent by participating in it. It was a grand success, seeing active participation from both students and faculty. It was particulary unique in the fact that all the participants voiced out their opinion with a complete freedom of thought.',
      imgPaths: ['pre.jpeg']
    },
    {
      title: 'CODING CONTEST FOR FIRST YEARS',
      date: '',
      for: '1st year IT students',
      description: 'A coding event on Hackerrank was conducted to understand and know more about their knowledge and expertise on the languages. To make the students loosen up a bit, a fun on-desk creative presentation and a small aptitude test were conducted. The actual contest consisted of four problem solving questions which were to be solved in either C/C++. The contest was about 80 minutes and after it ended all the doubts and solutions were discussed. An encouraging turnout of 92 students was seen for the same.',
      imgPaths: ['contest.jpeg']
    },
  
  {
    title: 'PR TO THE LATERAL ENTRIES (2022 PASSING BATCH)',
    date: '',
    for: 'Lateral Entry students (class of 2018-2022)',
    description: 'The lateral entry students with their entire class of 2018-2022 batch, from Information Technology Department were introduced to the ACM as a student chapter and how would one benefit as a member of ACM. It was an hour long interactive session and the response from the students was really positive and overwhelming.',
    imgPaths: ['prlate.jpeg']
  },
  {
    title: 'ACM SOLUTION CONTEST',
    date: '',
    for: '',
    description: 'The solution contest of ACM India is a way to engage all the chapters into competitive and collaborative participation and contribute to a social cause. The team ACM of VNRVJIET has also participated in this challenge by presenting an abstract to deal with agricultural problems in India. The main focus was on crop wastage and how it could be reduced using the modern technologies available.',
    imgPaths: ['solution.jpeg']
  },
  {
    title: 'INTERACTION SESSION BY 4TH YEARS',
    date: '',
    for: '2nd and 3rd year IT students',
    description: 'An interaction was organized by ACM VNRVJIET between 4th years and upcoming 2nd and 3rd year IT students. Students who were placed in top companies like Microsoft, EA Sports, TSC etc came to interact with juniors. Notes on how to improve coding skills, points on internships and academics. It was a complete one hour session. ACM has conducted the first ever student friendly interactive session with the help of a few final years and the alumni. The seniors have talked about what all they learned in these past few years as a B.Tech student, their struggles, achievements, mistakes and basically life at VNR and after. All the doubts, misconceptions, advices and facts were discussed during the last 20min of this one hour fully packed interactive session.',
    imgPaths: ['interact.jpeg']
  },

  {
    title: 'HOURS OF CODE',
    date: '',
    for: '2nd year IT students',
    description: 'Hours of code, a coding event on HackerRank was conducted to understand and know more about their knowledge and expertise on the languages. To make the students loosen up a bit, a fun on-desk creative presentation and a small aptitude test was conducted. The actual contest consisted of four problem solving questions which were to be solved in either C/C++. The contest was about 80 minutes and after it ended all the doubts and solutions were discussed.',
    imgPaths: ['hrs.jpeg']
  },

  
]