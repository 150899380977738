import React from 'react'
import '../styles/home.css'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Cover() {
    const props=[
    {
        imgPath:'carousel3-min.jpg',
        desc:'WCC 2.0',
    },
    {
        imgPath:'carousel2-min.jpg',
        desc:'Webathon'
    },
    {
        imgPath:'carousel1-min.jpeg',
        desc:'WCC 2.0'
    },
    {
        imgPath:'carousel5-min.jpg',
        desc:'Webathon'
    },
    {
        imgPath:'carousel6-min.jpeg',
        desc:'Webathon'
    }
    ]
// ...
return (
    <div className='home-cover'>
      <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} 
      showArrows={false} showStatus={false} 
      swipeable={true} transitionTime={'1000ms'}>
        {props.map((content, idx) => (
          <div key={idx}>
            <div className='imagecover'>
              <img src={require(`../imagProps/carousel/${content.imgPath}`)} 
              alt='event-img'
              className=' w-[50vh] h-[50vh] sm:h-[90vh] sm:w-full object-cover'
              />
              <div className="bottom-left text-4xl sm:font-sans font-semibold sm:text-7xl ">{content.desc}</div>
              <div className='image-overlay'></div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
  // ...
  
}

export default Cover