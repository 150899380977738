export const FacultyTestimonials=[
    {
        Name:"Dr. C. D Naidu",
        Desig:"Principal VNRVJIET",
        Content:"It is really important to know about modern technologies and activities moduling each and everyday. It was really good to see that the students and staff of ACM student chapter taking the responsibility by creating a place where we can learn , teach each other and share the knowledge both at the Academic level and in competitive fields.",
        imgPath:"princi.jpg"
    },
    {
        Name:"Dr. N. Mangathayaru",
        Desig:"IT HOD",
        Content:"Team ACM is targeted to bring out the hidden talents and introducing how the real world of a Engineer works.It always works on improving the soft skills and making students experienced in the engineering sector.Events which was conducted by ACM are making students to develop their teamwork and individual skills It was pleasure to watch Team ACM how they Moulding the careers of upcoming students.",
        imgPath:"IT-hod.png"
    },
    {
        Name:"Mr. S. Murali Mohan",
        Desig:"Faculty Coordinator",
        Content:"ACM, the student chapter from IT department, it was created to help and encourage the students to learn something new. To let the students know how the actual corporate world works outside. The Volunteers of ACM always strived to keep this student chapter in the first row having the only motto of providing a platform to know about the technical knowledge and support each other.",        
        imgPath:"facultycor1.jpg"
    }
]