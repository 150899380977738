export const DetailsUpcmng=[
    {
        posterPath:'sculpt.png',
        desc:"Team ACM VNRVJIET is excited to announce that we are hosting an innovative web page designing contest, WEBSCULPT! This event is crafted to challenge and enhance your web development skills, pushing you to new heights of creativity and technical proficiency.",
        title:"Web Sculpt",
        regLink:" https://bit.ly/Techspotlight"
    },
    {
        posterPath:'coding.png',
        desc:"Team ACM VNRVJIET cordially invites enthusiastic programmers to become a part of our coding team.Don’t miss this chance to be part of a transformative experience where your coding skills can elevate talents and inspire others.",
        title:'Coding Team Recruitment',
        regLink:" https://unstop.com/o/OXDU5Jt?lb=R0aLzrk"
    }
]