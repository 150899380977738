export const events2022 = [
    {
      title: 'Code Spree',
      date: '6 September 2022',
      for: '1st year ACM members (class of 2021-2025)',
      description: "Team ACM VNRVJIET conducted a coding contest for the first-year ACM membership holders. This contest is to create awareness about their coding skills and also let them know what a coding contest actually is. This is their first coding contest for the majority of them. This 90-minute long contest has improved their problem-solving abilities and time management during the contest. The winners of this contest are Sai Nandan, Rishab Gupta, Abhinay Goud, Raviteja Gundeti, and Mahesh Reddy. The winners will be awarded and provided with a winner's certificate.",
      imgPaths: ['image60.jpg']
    },
    {
      title: 'What is Hackathon?',
      date: '2 September 2022',
      for: '1st year IT (class of 2021-2025)',
      description: 'Team ACM VNRVJIET conducted a talk session for the first-year IT students on what a hackathon is. We wanted to emphasize the significance and opportunities provided by taking part in various hackathons. Our speakers, K. Sharwani, Ch. Sai Neha, and B. Avinash, shared their hackathon experiences. We gave them a clear idea about hackathons and also made everyone participate in an activity where they were given a set of problem statements, from which each team had to explain their approach to the problem statement that they had chosen. At the end of the session, the team with the best approach was awarded. This session has enhanced their innovation and knowledge of hackathons.',
      imgPaths: ['image45.jpg', 'image54.png', 'image64.png', 'image68.png']
    },
    {
      title: "LET'S TALK PLACEMENTS",
      date: '18 July 2021',
      for: '',
      description: "Placements play a huge role in every student's life. The preparation involved for it can be confusing though. Let's talk Placements session delved deeper into the recruitment pattern, placement pointers and the firsthand experiences of our seniors at NCR & TCS Digital. The panelists shared their interview experiences.",
      imgPaths: ['placement.jpeg']
    },
    {
      title: 'ACM CODE HEIST',
      date: '6 June 2021',
      for: '2nd year IT students',
      description: "Team ACM_VNRVJIET thanks all of the entrants for participating in the Codeheist contest conducted on 6th June, 2021 for all IT 2nd year students. We are proud to announce the winners and also congratulate the participants. If your name is not indexed as one of the winners do not get discouraged as there can be many more opportunities in the future. Such competitions are stepping stones, and they let you learn. Hence, a fine mindset is what makes you extraordinary.",
      imgPaths: ['webdesign.lpeg']
    },
    {
      title: 'FUNDAMENTALS OF WEBSITE DESIGN',
      date: '29 May 2021 - 1 June 2021',
      for: '',
      description: "To create a doorway into the world of visual prowess, a four-day workshop on Web Designing was conducted by ACM VNRVJIET in collaboration with the department of Information Technology. The workshop was led by a team of students from 2018-2022 batch in the IT department.",
      imgPaths: []
    },
    {
      title: 'ARTIFICIAL INTELLIGENCE AND ITS APPLICATIONS',
      date: '28 May 2021',
      for: 'IT students',
      description: "Our intelligence makes us human, and AI is an extension of that quality. Simply put, AI is an approach that makes a computer, a robot, or a product think how humans think. As one of the booming technologies of computer science, Artificial Intelligence is ready to create a new revolution in the world by making intelligent machines. The future of work involves automation, and acquiring such a skill is a sure way to future-proof your career. ACM_VNRVJIET has organized a webinar on \"Artificial Intelligence and its Applications\" on 28th May 2021 from 2:45pm - 4:15pm. Presenter: DR. D. Srinivasa Rao, Associate Prof, IT Dept, VNRJIET.",
      imgPaths: ['AI.jpeg']
    },
    {
      title: 'R-PROGRAMMING WORKSHOP',
      date: '28 April 2021 - 1 May 2021',
      for: 'IT students',
      description: "R-Programming is sometimes called the golden child of Data Science. Equipped with a rich set of visualization features and built-in functionalities, this language has marked its spot as one of the most preferred languages by Data Scientists. R will only continue to become increasingly important in the future. The Department of Information Technology in collaboration with ACM VNRVJIET has conducted the R Programming Online Workshop, that held from 28th April 2021 to 1st of May 2021 for all 1st year IT Department students. All participants who met the mentioned eligibility were issued an e-certificate.",
      imgPaths: ['R.jpeg']
    }
  ]