export const events2019 = [
    {
      title: 'PR FOR FIRST YEARS',
      date: '21 February 2019',
      for: '1st year IT students',
      description: 'ACM is a student body organization formed to spread awareness among its young peers. On 21st Feb 2019, the Second year students of team ACM have taken an initiative for a PR session for the fresher\'s of VNR-IT. In this short 30 min session, the students have covered the importance of having crisp knowledge about upcoming technological advancements in this rapidly transforming era and how ACM is going to help the students face those core challenges. Here at ACM we believe communication is the key, so the students have been assured the seniors support and shared experiences.',
      imgPaths: ['pr119.jpeg']
    },
    {
        title: 'REPUBLIC DAY CELEBRATIONS',
        date: '26 Januray 2020',
        for: '',
        description: 'The ACM VNRVJIET student chapter participated in Salute parade for the second time this year. The Salute parade happens every year on the eve of the country\'s Republic Day in VNR Vignana Jyothi Institute Of Engineering And Technology. All the student chapters of the college participate in the parade with different themes. Each student chapter showcases their placards and posters representing their theme in the parade. Team ACM came up with the theme of " Armed Forces" for this year\'s Salute event.',
        imgPaths: ['rep2020.jpeg']
      },
      {
        title: 'INTERACTIVE SESSION',
        date: '25 January 2020',
        for: '1st year students',
        description: 'There is no such things as a self-made man. You will reach your goals only with the help of others". ACM has always working to develop the master mind\'s and to introduce how the real world of a Engineer works. On 25th Jan, ACM conducted an Interaction session with the help of alumni, final year students and the founder\'s of a Start-up. During the session, they shared the up\'s and down\'s of their careers and experiences with wonderful words of inspiration and fruitful advices. As most of the first year students were involved in the session they gave a proper guidance towards their goals and clarified their doubts.',
        imgPaths: ['interact.jpeg']
      },
      {
        title: 'CYBER SECURITY WORKSHOP',
        date: '13 December 2019 - 14 December 2019',
        for: '2nd year IT students (class of 2018-2022)',
        description: 'A two day cyber security workshop was held on the 13th and 14th of December 2019 for the 2018-2022 batch. Cybersecurity is the body of technologies, processes, and practices designed to protect networks, computers and data from attack, damage, and unauthorized access. Interest among the students was high for this event, seeing an attendance of 95 students. They were engaged in a hands-on learning environment with the seniors delivering the session. Simple tasks were given to the participants to try out the skills learnt by them. All in all, the session was truly enticing and fun.',
        imgPaths: ['cyberwork.jpeg']
      },
      {
        title: 'PR FOR FIRST YEARS',
        date: '21 February 2019',
        for: '1st year IT students',
        description: 'ACM is a student body organization formed to spread awareness among its young peers. On 21st Feb 2019, the Second year students of team ACM have taken an initiative for a PR session for the fresher\'s of VNR-IT. In this short 30 min session, the students have covered the importance of having crisp knowledge about upcoming technological advancements in this rapidly transforming era and how ACM is going to help the students face those core challenges. Here at ACM we believe communication is the key, so the students have been assured the seniors support and shared experiences.',
        imgPaths: ['pr1.jpeg']
      },




    
  ]