export const Testimonials2223=[
    {
        Name:"Chandrakethan S",
        Desig:"Webmaster(2023-2024)",
        Content:"ACM VNRVJIET is the best platform that any student could have, to experience life as a student to the fullest. The chapter is always devoted to finding the requirements of the students in order to bridge the gap between academics and overall growth of the student to help them become a better individual and excel as a team player and stand out from the competitive world around them. It successfully brought up many highly acclaimed programs with great zeal. I am a grateful member of this chapter which helped me to kick start, understand and explore many things that helped me grow both personally and professionally. Everything about this chapter fascinated me, inspired me to join as a volunteer and become a part of its vision and mission.",
        imgPath:"csk2.jpeg"
    },
    {
        Name:"Siddharth Reddy Nimmala",
        Desig:"Chairperson(2023-2024)",
        Content:"My journey as a volunteer at ACM VNRVJIET has been a transformative experience in personal and professional growth. I've had the chance to enhance my leadership, communication, and management abilities, which are essential for success in both the academic and professional world. These skills are not only valuable to the student chapter but also undoubtedly benefited me.        ",
        imgPath:"sid2.jpeg"
    },
    {
        Name:"Sravya Reddy",
        Desig:"Vice Chairperson(2023-2024)",
        Content:"Being part of ACM VNRVJIET taught me so much from valuable lessons in teamwork and collaboration to personal growth and expanding my network. Each task I undertook felt like a building block, leading me towards more knowledge and meaningful connections. ",
        imgPath:"SR.jpeg"
    },
    {
        Name:"Praneetha Madala",
        Desig:"Membership Chair(2023-2024)",
        Content:"Being a volunteer at ACM VNRVJIET has helped me build my personality in all ways possible. I learned many new things here, something I could never learn in the classroom. I'm immensely grateful for all the connections, knowledge, and opportunities it has brought into my life.",
        imgPath:"Pm.jpeg"
    },
    {
        Name:"Kambhampati Sai Harsha Vardhan",
        Desig:"Activities Cordinator(2023-2024)",
        Content:"I never thought that I would join a Student Chapter like ACM VNRVJIET when I first arrived. However, from taking the membership to becoming a volunteer, it was an amazing experience. The main thing that I believe I gained from being a part of this is all the people with whom I got acquainted, people whom I might have never known if I had never joined ACM.        ",
        imgPath:"harsh2.jpeg"
    },
    {
        Name:"Krishna Sai B",
        Desig:"Treasurer(2023-2024)",
        Content:"It is believed that a person’s life is molded in the early 20s and it is very important to be in the right place. In that case, I am glad that ACM VNRVJIET happened to me. ACM VNRVJIET has been a great contributor to the development of my personality. I have established my leadership, time management, and team skills and have also been able to advance these skills. From participating in events to conducting events through ACM VNRVJIET, my learnings and experiences will always be my fundamentals for any milestone I achieve.",
        imgPath:"Ks.jpeg"
    },

]